<template>
  <div class="content">
    <el-row>
      <el-col :span="3" :xs="6">
        <div :class="show == true ? 'title' : '_title'">
          <span>
            服务介绍
          </span>
        </div>
      </el-col>
      <el-col :span="21" :xs="18">
        <div class="list">
          <ul>
            <li @click="li_one" :class="one == true ? 'li' : '_li'">
              家具物流
            </li>
            <li @click="li_two" :class="two == true ? 'li' : '_li'">
              智慧仓储
            </li>
            <li @click="li_three" :class="three == true ? 'li' : '_li'">
              家具安装
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <div v-if="one == true" :class="show == true ? 'left' : '_left'">
      <el-row>
        <el-col
          :span="6"
          :xs="12"
          v-for="(item, index) in logisticsData.slice(0, 4)"
          :key="index"
        >
          <div style="padding:0 5px">
            <div>
              <img
                :src="item.noticePicture + '?imageView2/1/w/400/h/200/q/85'"
                alt=""
              />
            </div>
            <div
              style="font-size:14px;font-weight:600;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
            >
              {{ item.noticeTitle }}
            </div>
            <div
              :class="show == true ? '_hei' : 'hei'"
              style="font-size:12px;padding-top:3px;word-break: break-all;"
            >
              {{ item.noticeDesc }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="two == true" :class="show == true ? 'left' : '_left'">
      <el-row>
        <el-col
          :span="6"
          :xs="12"
          v-for="(item, index) in storageData.slice(0, 4)"
          :key="index"
        >
          <div style="padding:0 5px">
            <div>
              <img
                :src="item.noticePicture + '?imageView2/1/w/400/h/200/q/85'"
                alt=""
              />
            </div>
            <div
              style="font-size:14px;font-weight:600;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
            >
              {{ item.noticeTitle }}
            </div>
            <div
              :class="show == true ? '_hei' : 'hei'"
              style="font-size:12px;padding-top:3px;word-break: break-all;"
            >
              {{ item.noticeDesc }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="three == true" :class="show == true ? 'left' : '_left'">
      <el-row>
        <el-col
          :span="6"
          :xs="12"
          v-for="(item, index) in installData.slice(0, 4)"
          :key="index"
        >
          <div style="padding:0 5px">
            <div>
              <img
                :src="item.noticePicture + '?imageView2/1/w/400/h/200/q/85'"
                alt=""
              />
            </div>
            <div
              style="font-size:14px;font-weight:600;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
            >
              {{ item.noticeTitle }}
            </div>
            <div
              :class="show == true ? '_hei' : 'hei'"
              style="font-size:12px;padding-top:3px;word-break: break-all;"
            >
              {{ item.noticeDesc }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";
// import { HomeController } from "@/controllers/_controller.js";

export default {
  data() {
    return {
      one: true,
      two: false,
      three: false,
      show: true,
      vm: {
        // 公告搜索对象
        search: {
          //机构Id
          OrgId: "",
          //租户Id
          TenantId: "",
          // 公告标题
          NoticeTitle: "",
          // 选中的id记录
          NoticeTypeCode: "",
          ReleaseTimeStart: "",
          ReleaseTimeEnd: "",
        },
        pageIndex: 1,
        // 每页的数据
        pagesize: 5,
      },
      installData: [],
      storageData: [],
      logisticsData: [],
    };
  },
  mounted() {
    setInterval(() => {
      this.widt();
    }, 100);
    this.logistics();
    this.storage();
    this.install();
  },
  methods: {
    widt() {
      if (window.innerWidth < 700) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    li_one() {
      this.one = true;
      this.two = false;
      this.three = false;
    },
    li_two() {
      this.one = false;
      this.two = true;
      this.three = false;
    },
    li_three() {
      this.one = false;
      this.two = false;
      this.three = true;
    },
    // 家具物流
    async logistics() {
      // let PagingNotice = new HomeController();
      // let res = await PagingNotice.PagingNoticeDal({
      //   NoticeTitle: "",
      //   NoticeTypeCode: "GG01",
      //   ReleaseTimeStart: "",
      //   ReleaseTimeEnd: "",
      //   pageIndex: 1,
      //   pagesize: 5,
      // });
      // console.log(res, "接口改的");
      let url = `${BASE_URL}/api/home/PagingNotice`;
      const res = await this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: {
          search: {
            //机构Id
            OrgId: "",
            //租户Id
            TenantId: "",
            NoticeTitle: "",
            NoticeTypeCode: "GG01",
            ReleaseTimeStart: "",
            ReleaseTimeEnd: "",
          },
          pageIndex: 1,
          pagesize: 5,
        },
      });
      this.logisticsData = res.data.content.rows;
      // this.logisticsData = res.content.datas;
    },
    // 智慧仓储
    async storage() {
      let url = `${BASE_URL}/api/home/PagingNotice`;
      const res = await this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: {
          search: {
            //机构Id
            OrgId: "",
            //租户Id
            TenantId: "",
            NoticeTitle: "",
            NoticeTypeCode: "GG02",
            ReleaseTimeStart: "",
            ReleaseTimeEnd: "",
          },
          pageIndex: 1,
          pagesize: 5,
        },
      });
      this.storageData = res.data.content.rows;
    },
    // 家具安装
    async install() {
      let url = `${BASE_URL}/api/home/PagingNotice`;
      const res = await this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: {
          search: {
            //机构Id
            OrgId: "",
            //租户Id
            TenantId: "",
            NoticeTitle: "",
            NoticeTypeCode: "GG03",
            ReleaseTimeStart: "",
            ReleaseTimeEnd: "",
          },
          pageIndex: 1,
          pagesize: 5,
        },
      });
      this.installData = res.data.content.rows;
    },
  },
};
</script>
<style scoped lang="scss">
.left {
  text-align: left;
  padding-top: 15px;
  // padding-left: 30px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}
._left {
  text-align: left;
  padding-top: 15px;
  // margin-left: 20px;
  margin-left: 3.125vw;
  margin-right: 3.125vw;
  // margin-right: 20px;
}
img {
  width: 100%;
}
.content {
  width: 100%;
  padding-top: 20px;
  // height: 300px;
}
.title {
  margin-left: 53px;
  text-align: left;
  font-size: 18px;
  // padding-top: 30px;
}
._hei {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 82px;
}
.hei {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 16vw;
  padding-bottom: 7px;
}
._title {
  // margin-left: 20px;
  margin-left: 3.125vw;
  text-align: left;
  font-size: 18px;
  // padding-top: 30px;
}
li:hover {
  cursor: pointer;
}
.list {
  text-align: left;
  // border-bottom: 1px solid #ccc;
}

ul {
  margin: 0;
  padding-inline-start: 0px;
}
.liStyle {
  display: inline-block;
  padding: 2px 0px;
  font-weight: 600;
  margin-right: 50px;
  list-style: none;
}
._liStyle {
  display: inline-block;
  padding: 2px 0px;
  font-weight: 600;
  margin-right: 1.5625vw;
  list-style: none;
}
.li {
  display: inline-block;
  // padding: 2px 0px;
  font-weight: 600;
  list-style: none;
  color: #6282f5;
  margin-right: 1.5625vw;
  border-bottom-width: 30px;
  border-bottom: 1px solid #6282f5;
}
._li {
  display: inline-block;
  // padding: 2px 0px;
  font-weight: 600;
  margin-right: 1.5625vw;
  list-style: none;
}
</style>
