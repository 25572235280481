<template>
  <div class="tabs">
    <el-row type="flex" justify="center" class="pt">
      <el-col :span="22">
        <div class="tabTitle">
          <div @click="load" :class="hide == true ? 'active' : 'noactive'">
            线路查询
          </div>
          <div @click="waybill" :class="show == true ? 'active' : 'noactive'">
            运单查询
          </div>
        </div>
        <div class="search">
          <div v-if="show == true" class="ipt">
            <WaybillSearch></WaybillSearch>
          </div>
          <div v-if="hide == true" class="ipt"><Linesearch></Linesearch></div>
        </div>
        <div class="service">
          <div class="cnhTitle">
            货运招标
          </div>
          <div class="engTitle">
            Freight
          </div>
        </div>
        <el-table
          :border="true"
          :data="tableData"
          :header-cell-style="{ background: '#f7f7f7' }"
          style="width: 100%"
          highlight-current-row
        >
          <el-table-column
            prop="waybillCode"
            label="运单编号"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="货物名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <!-- enterpriseName -->
          <el-table-column
            prop="enterpriseAdress"
            label="提货地址"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="customerAddress"
            label="收件地址"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="dispatchLastDataTime"
            label="方案截止日期"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.dispatchLastDataTime | data }}
            </template>
          </el-table-column>
          <el-table-column
            prop="totalCount"
            label="货品总件数"
            align="center"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";

import Linesearch from "../views/line-search.vue";
import WaybillSearch from "../views/waybill-search.vue";
export default {
  name: "tabs",
  components: {
    Linesearch,
    WaybillSearch,
  },
  data() {
    return {
      tableData: [],
      bm: {
        search: {
          routeName: "",
        },
        pageIndex: 1,
        pageSize: 10,
        noCount: false,
      },
      reverse: true,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
          color: "#0bbd87",
        },
      ],
      show: false,
      hide: true,
      input: "",
    };
  },
  mounted() {
    this.carousel();
  },
  methods: {
    waybill() {
      this.show = true;
      this.hide = false;
    },
    load() {
      this.show = false;
      this.hide = true;
    },
    carousel() {
      let url = `${BASE_URL}/api/Home/PagingTMSWayBill`;
      this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: this.bm,
      }).then((res) => {
        this.tableData = res.data.content.rows;
        console.log(res);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.titleItem {
  padding-left: 0px !important;
}
.el-pagination {
  text-align: right;
}
.search {
  min-height: 150px;
  border: 1px solid #ccc;
  padding: 0px 10px;
  border-bottom-left-radius: 6;
  border-bottom-right-radius: 6;
}
.cnhTitle {
  // freight
  text-align: center;
  color: #6282f5;
  font-size: 18px;
  padding-top: 30px;
  // padding-bottom: 30px;
}
.engTitle {
  padding-bottom: 30px;
  text-align: center;
  color: #bcbcbc;
  padding-top: 5px;
}
.ipt {
  padding-top: 10px;
}
.el-input__inner {
  width: 300px;
  height: 30px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.el-col {
  // border-radius: 4px;
  text-align: left;
}

.pt {
  padding-top: 20px;
}
.tabTitle {
  // border-bottom: 2px solid #6282f5;
  display: flex;
  text-align: center;
}
.hide {
  display: none;
  // height: 180px;
}
.show {
  display: block;
  // height: 180px;
}
.noactive {
  padding: 5px 15px 0 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  line-height: 24px;
  background-color: #f9f9f9;
}
.active {
  padding: 5px 15px 0 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  line-height: 24px;
  background-color: #d3dce6;
}
.bg {
  margin-top: 10px;
  // padding-left: 10px;
  background-color: #fff;
  // border: 1px solid #f9f9f9;
}
.title {
  font-size: 12px;
  padding: 5px 0 8px 0;
}
.input {
  background-color: #f9f9f9 !important;
}
.btn {
  margin: 10px 0;
  padding: 10px 25px;
}
.titleItem {
  // width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  // box-sizing: border-box;
  // border: 1px solid;
  .tableBorder {
    border: 1px solid #f9f9f9;
  }
  .el-table {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }
}
</style>
